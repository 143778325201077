 &--top-center {
   top: 1em;
   left: 50%;
   margin-left: -($rt-toast-width / 2);
 }

 &--bottom-center {
   bottom: 1em;
   left: 50%;
   margin-left: -($rt-toast-width / 2);
 }

 body {
   margin: 0;
 }